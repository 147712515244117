ptz-attachment {
  .ptz-container {
    max-width: 42rem;
  }

  .ptz-text-optional {
    color: $petz-color-neutral-dark;
    font-size: $petz-font-size-2xs;
    font-weight: $petz-font-normal;
  }

  .ptz-title-text {
    font-size: $petz-font-size-2xs;
    font-style: normal;
    font-weight: $petz-font-bold;
    line-height: $petz-font-lineheight-lg;
    margin-bottom: $petz-spacing-3xs;
  }

  .ptz-drop-zone {
    border: $petz-border-md dashed $petz-color-neutral-dark;
    border-radius: $petz-spacing-3xs;
    padding-block: $petz-spacing-xl;
    text-align: center;
    width: 100%;

    .ptz-upload {
      color: $petz-color-primary-brand;
      cursor: pointer;
      font-weight: $petz-font-bold;
    }

    .ptz-none-input {
      display: none;
    }

    .ptz-text-input {
      margin: $petz-spacing-3xs;
    }

    p {
      margin: 0;
    }

    &:hover {
      border: $petz-border-md dashed $petz-color-primary-brand;
      transition: ease-in-out 0.2s;
    }
  }

  .ptz-drag-over {
    background-color: #e3f2fd;
    border-color: $petz-color-primary-brand;
  }

  .ptz-text-footer {
    display: flex;
    justify-content: flex-end;
  }

  .ptz-title-upload {
    color: $petz-color-neutral-darker-accent;
    font-size: $petz-font-size-2xs;
    font-weight: $petz-font-bold;
  }

  .ptz-list-preview {
    display: grid;
    grid-template-columns: auto auto;
    gap: $petz-spacing-sm;

    @media (max-width: $petz-breakpoint-md) {
      grid-template-columns: auto;
    }
  }
  .ptz-container-uploader {
    max-width: 18.75rem;
    position: relative;

    .ptz-preview-uploader.above-limit {
      border: 0.063rem solid $petz-color-sup-red-darker-accent;
    }

    .ptz-preview-uploader {
      align-items: flex-start;
      background-color: $petz-color-neutral-white;
      border: 0.063rem solid $petz-color-neutral-light;
      border-radius: $petz-spacing-2xs;
      display: flex;
      justify-content: space-between;
      padding: $petz-spacing-sm;
      position: relative;

      .ptz-icon-cancel {
        border: none;
        background-color: transparent;
        cursor: pointer;
        position: absolute;
        right: $petz-spacing-3xs;
      }

      .ptz-img-with-progress {
        align-items: flex-end;
        display: flex;
        gap: 1.125rem;

        .ptz-Preview-img {
          width: $petz-spacing-4xl;
          height: $petz-spacing-4xl;
        }

        .ptz-progress-bar {
          min-width: 11.575rem;
          padding-bottom: $petz-spacing-4xs;
        }

        .ptz-position-progress {
          align-items: baseline;
          display: flex;
          flex-direction: column;
          height: $petz-spacing-4xl;
          justify-content: space-between;

          .ptz-limit-image {
            display: contents;
            justify-content: space-between;
            padding-bottom: $petz-spacing-4xs;

            .ptz-img-title-not-supported {
              color: $petz-color-neutral-darker-accent;
              font-size: $petz-font-size-2xs;
              line-height: $petz-font-size-xs;
              margin: 0;
            }
          }
        }
      }

      @media (max-width: $petz-breakpoint-md) {
        max-width: none;
      }
    }

    .ptz-text-limit {
      color: $petz-color-sup-red-darker-accent;
      font-size: $petz-font-size-3xs;
      font-weight: $petz-font-bold;
      top: 6.2rem;
      right: 0;
      margin: 0;
      position: absolute;
    }
  }
}
