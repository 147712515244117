import { Component, Event, EventEmitter, Listen, Prop, State, h } from '@stencil/core';
import { PTZAttachmentTypes } from './types/ptz-attachment.types';

@Component({
  tag: 'ptz-attachment',
  styleUrl: 'ptz-attachment.scss',
  shadow: false,
})
export class PTZAttachment {
  @Prop() imageData: string;
  @State() fileNames: string[] = [];
  @State() filePreviews: PTZAttachmentTypes.FilePreview[] = [];
  @State() uploadProgress: number = 0;
  host: any;

  @Event() filesChange: EventEmitter<PTZAttachmentTypes.FilesChangeEventDetail>;

  constructor() {
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
  }

  handleFileInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    if (files) {
      const fileList = Array.from(files);
      const filesToAdd = fileList.slice(0, 4 - this.filePreviews.length);

      const newPreviews = [];

      filesToAdd.forEach(file => {
        this.fileNames.push(file.name);
        newPreviews.push(this.createPreview(file));
      });

      if (newPreviews.length > 0) {
        Promise.all(newPreviews).then(previews => {
          this.filePreviews = [...this.filePreviews, ...previews];
          const convertedFiles = this.filePreviews.map(preview => this.dataURItoFile(preview.url, preview.name));
          this.filesChange.emit({ files: convertedFiles });
        });
      }

      input.value = '';
    }
  }

  dataURItoFile(dataURI: string, fileName: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.match(/^data:([^;]+)/)[1];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const file = new File([ab], fileName, { type: mimeString });
    return file;
  }

  createPreview(file: File): Promise<PTZAttachmentTypes.FilePreview> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        const newPreview = { name: file.name, url: reader.result as string, aboveLimit: file.size > 5 * 1024 * 1024 };

        const img = new Image();
        img.onload = () => {
          const totalSize = file.size;
          const loadedSize = totalSize;
          const progress = Math.round((loadedSize / totalSize) * 100);
          this.uploadProgress = progress;
        };
        img.src = reader.result as string;

        resolve(newPreview);
      };
    });
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();

    const files = event.dataTransfer?.files;

    if (files) {
      const fileList = Array.from(files);
      const filesToAdd = fileList.slice(0, 4 - this.filePreviews.length);

      filesToAdd.forEach(file => {
        const isAboveLimit = file.size > 5 * 1024 * 1024;

        if (isAboveLimit) {
          this.filePreviews = [{ name: file.name, url: '', aboveLimit: true }];
        } else {
          const newFileName = file.name;
          this.fileNames.push(newFileName);
          this.previewImages(file);
        }
      });
    }
  }

  previewImages(file: File) {
    if (this.filePreviews.length >= 4) {
      console.warn('Você excedeu o número máximo de arquivos permitidos (limite: 4)');
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const newPreview = { name: file.name, url: reader.result as string };
      this.filePreviews.push(newPreview);
      this.imageData = reader.result as string;
    };
    reader.onprogress = (event: ProgressEvent<FileReader>) => {
      if (event.lengthComputable) {
        const percentLoaded = Math.round((event.loaded / event.total) * 100);
        this.uploadProgress = percentLoaded;
      }
    };

    reader.readAsDataURL(file);
  }

  cancelPreview(index: number) {
    this.filePreviews = this.filePreviews.filter((_, i) => i !== index);
  }

  renderLabel(preview) {
    let shortenedLabel = preview.name?.slice(0, 20);
    if (preview.name?.length >= 20) {
      shortenedLabel += '...';
    }

    const dotIndex = preview.name?.lastIndexOf('.');
    if (dotIndex !== -1 && preview.name?.length >= 20) {
      if (dotIndex > shortenedLabel.length) {
        const extension = preview.name?.slice(dotIndex + 1);
        shortenedLabel += `.${extension}`;
      }
    }

    return shortenedLabel;
  }

  replaceFile(index: number) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/png';
    input.addEventListener('change', event => {
      const newFile = (event.target as HTMLInputElement).files[0];
      if (newFile) {
        const isAboveLimit = newFile.size > 5 * 1024 * 1024; // 5 MB

        if (isAboveLimit) {
          console.warn('O novo arquivo excede o tamanho máximo permitido.');
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            const newPreview = { name: newFile.name, url: reader.result as string };
            if (this.filePreviews[index]) {
              this.filePreviews[index] = newPreview;
            } else {
              this.filePreviews.push(newPreview);
            }
            this.imageData = reader.result as string;
          };
          reader.readAsDataURL(newFile);
        }
      }
    });
    input.click();
  }

  @Listen('dragover', { target: 'window' })
  handleWindowDragOver(event: DragEvent) {
    event.preventDefault();
  }

  @Listen('drop', { target: 'window' })
  handleWindowDrop(event: DragEvent) {
    event.preventDefault();
  }

  render() {
    return (
      <div class="ptz-container">
        <p class="ptz-title-text">
          Adicionar arquivos <small class="ptz-text-optional">(opcional)</small>
        </p>
        <div class="ptz-drop-zone" onDragOver={event => this.handleDragOver(event)} onDrop={event => this.handleDrop(event)}>
          <ptz-icon brand="ptz" variant="line" color="primary-brand" name="cloud-upload" size="3xl" />

          <p class="ptz-text-input">Você pode arrastar e soltar os arquivos aqui</p>
          <p>
            ou
            <label htmlFor="fileInput" class="ptz-upload">
              {' '}
              enviar do seu computador
            </label>
            <input type="file" onChange={event => this.handleFileInputChange(event)} id="fileInput" class="ptz-none-input" multiple />
          </p>
        </div>
        <div class="ptz-text-footer">
          <p class="ptz-text-optional">Formatos permitidos: jpeg e png, até 10MB</p>
        </div>

        {this.filePreviews.length > 0 && (
          <div>
            <p class="ptz-title-upload">Arquivos enviados:</p>
            <div class="ptz-list-preview">
              {this.filePreviews.map((preview, index) => {
                return (
                  <div class="ptz-container-uploader">
                    <div class={`ptz-preview-uploader ${preview.aboveLimit ? 'above-limit' : ''}`} key={index}>
                      <div class="ptz-img-with-progress">
                        <img class="ptz-Preview-img" src={preview.url} alt="img" />
                        <div class="ptz-position-progress">
                          {preview.aboveLimit ? (
                            <div class="ptz-limit-image">
                              <ptz-link onClick={() => this.replaceFile(index)} kind="darker">
                                Enviar outro arquivo
                              </ptz-link>

                              <p class="ptz-img-title-not-supported">{this.renderLabel(preview)}</p>
                            </div>
                          ) : (
                            <div>
                              {this.uploadProgress === 100 ? (
                                <ptz-icon brand="ptz" variant="solid" color="sup-green-darker-accent" name="check-circle-solid" size="md" />
                              ) : (
                                <p>{this.uploadProgress}%</p>
                              )}
                            </div>
                          )}
                          {!preview.aboveLimit && <ptz-progress-bar percentDisable label={this.renderLabel(preview)} class="ptz-progress-bar" />}
                        </div>
                      </div>

                      <button class="ptz-icon-cancel" onClick={() => this.cancelPreview(index)}>
                        <ptz-icon brand="ptz" variant="line" color="neutral-darker-accent" name="petz-cancel" size="lg" />
                      </button>
                    </div>
                    {preview.aboveLimit && <p class="ptz-text-limit">O tamanho máximo da foto é de até 10MB.</p>}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
